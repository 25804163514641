<template>
  <div>
    <validation-provider
      #default="{ errors }"
      name="category"
      :rules="requiredField ? 'required' : ''"
    >
      <b-form-group
        label="Categoria"
        label-for="category"
      >
        <v-select
          id="category"
          v-model="category"
          :class="{
            'error-select': errors.length > 0,
            'category-readonly': readonly,
            'category-edit': !readonly,
          }"
          :filter="fuseSearch"
          label="name"
          :options="dataOptions"
          :placeholder="readonly ? '' : 'Categoria'"
          :disabled="readonly"
          :clearable="!readonly"
          :multiple="multiple"
          @open="onCreate()"
        >
          <template #option="{ name }">
            <span>{{ name }} </span>
          </template>

          <template #selected-option="{ name }">
            <span
              v-if="!readonly"
            >
              {{ name }}
            </span>
            <b-badge
              v-else
              class="text-capitalize"
              variant="primary"
            >
              {{ name | truncate(18, '...') }}
            </b-badge>
          </template>

          <template #no-options>
            Não há opções de categorias.
          </template>
        </v-select>
        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>
  </div>
</template>
<script>
import {
  BFormGroup,
  BBadge,
} from 'bootstrap-vue'

import {
  ValidationProvider,
} from 'vee-validate'

import {
  required,
} from '@validations'

import vSelect from 'vue-select'
import Fuse from 'fuse.js'
import syslic from '@/syslic'
import truncate from '@/components/utils/filters/truncate'

export default {
  components: {
    BFormGroup,
    BBadge,
    ValidationProvider,
    vSelect,
  },
  filters: {
    truncate,
  },
  model: {
    prop: 'categoryValue',
    event: 'categoryChange',
  },
  props: {
    categoryValue: {
      type: [Object, String, Array],
      required: true,
    },
    readonly: {
      type: Boolean,
      required: true,
    },
    requiredField: {
      type: Boolean,
      required: false,
      default: true,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    const dataOptions = []

    return {
      dataOptions,
      required,
    }
  },
  computed: {
    category: {
      get() {
        return this.categoryValue
      },
      set(value) {
        this.$emit('categoryChange', value)
      },
    },
  },
  created() {
    this.onCreate()
  },
  methods: {
    onCreate() {
      syslic
        .supplier
        .category
        .fetchCategories()
        .then(response => {
          this.dataOptions = response.data.results
          this.$emit('ready', true)
        })
        .catch(() => {
          this.$emit('ready', true)
        })
    },
    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ['name'],
        shouldSort: true,
      })
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.category-readonly .vs__deselect {
  display: none;
}

.category-edit .vs__deselect {
  fill: #ffffff;
}
</style>
