var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-provider',{attrs:{"name":"category","rules":_vm.requiredField ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Categoria","label-for":"category"}},[_c('v-select',{class:{
          'error-select': errors.length > 0,
          'category-readonly': _vm.readonly,
          'category-edit': !_vm.readonly,
        },attrs:{"id":"category","filter":_vm.fuseSearch,"label":"name","options":_vm.dataOptions,"placeholder":_vm.readonly ? '' : 'Categoria',"disabled":_vm.readonly,"clearable":!_vm.readonly,"multiple":_vm.multiple},on:{"open":function($event){return _vm.onCreate()}},scopedSlots:_vm._u([{key:"option",fn:function(ref){
        var name = ref.name;
return [_c('span',[_vm._v(_vm._s(name)+" ")])]}},{key:"selected-option",fn:function(ref){
        var name = ref.name;
return [(!_vm.readonly)?_c('span',[_vm._v(" "+_vm._s(name)+" ")]):_c('b-badge',{staticClass:"text-capitalize",attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(_vm._f("truncate")(name,18, '...'))+" ")])]}},{key:"no-options",fn:function(){return [_vm._v(" Não há opções de categorias. ")]},proxy:true}],null,true),model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }