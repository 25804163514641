<template>
  <div>
    <div v-show="!isLoaded">
      <spin-loader />
    </div>
    <div v-show="isLoaded">
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          md="12"
          lg="10"
        >
          <div
            v-if="hasPermissionToUpdate"
          >
            <b-row
              v-if="isViewMode"
            >
              <b-col
                class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
              >
                <b-dropdown
                  id="actions"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  right
                  class="dropdown-icon-wrapper"
                >
                  <template #button-content>
                    <feather-icon
                      icon="SettingsIcon"
                      size="16"
                      class="align-middle"
                    />
                  </template>

                  <b-dropdown-item
                    v-if="isViewMode"
                    id="bnt-edit"
                    :disabled="!hasPermissionToUpdate"
                    @click="enableEdition()"
                  >
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Editar</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    :disabled="!hasPermissionToDelete"
                    @click="showConfirmOrCancelDelete(supplierId)"
                  >
                    <svg-icon
                      type="mdi"
                      size="16"
                      :path="mdiTrashCanOutline"
                    />
                    <span class="align-middle ml-50">Remover</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-col>
            </b-row>
          </div>

          <validation-observer
            #default="{ handleSubmit, invalid }"
            ref="refFormObserver"
          >
            <b-form
              class="mt-1"
              @submit.prevent="handleSubmit(onSubmit)"
            >

              <supplier-info
                v-model="supplier"
                :required-fields="requiredFieldsParticipant"
                :readonly="isViewMode"
                @ready="syncLoad.ready('supplier-info')"
              />

              <address-edit-card
                :address="supplier"
                :is-view-mode="isViewMode"
                :required-fields="requiredFieldsAddress"
              />

              <contact-card
                v-if="!isCreateMode"
                :supplier="supplierId"
                :readonly="isViewMode"
              />

              <b-card
                no-body
                class="card-user-style"
              >
                <b-card-title class="text-black mt-2 ml-2 mb-0">
                  <svg-icon
                    type="mdi"
                    :path="mdiBankCircleOutline"
                  />
                  <span
                    class="d-inline font-weight-bolder ml-50"
                  >
                    Dados para Pagamento
                  </span>
                </b-card-title>
                <hr>
                <b-card-body class="pt-1">
                  <b-row>
                    <b-col cols="12">
                      <b-row>
                        <b-col
                          cols="12"
                          md="6"
                        >
                          <validation-provider
                            #default="validationContext"
                            name="pix_key"
                          >
                            <b-form-group
                              label="Chave PIX"
                              label-for="pix_key"
                            >
                              <b-form-input
                                id="pix_key"
                                v-model="supplier.pix_key"
                                :state="isViewMode ? null : getValidationState(validationContext)"
                                :placeholder="isViewMode ? '' : 'Chave PIX'"
                                :readonly="isViewMode"
                              />
                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col
                          cols="12"
                          md="6"
                        >
                          <validation-provider
                            #default="validationContext"
                            name="account_owner"
                          >
                            <b-form-group
                              label="Titular da Conta"
                              label-for="account_owner"
                            >
                              <b-form-input
                                id="account_owner"
                                v-model="supplier.account_owner"
                                :state="isViewMode ? null : getValidationState(validationContext)"
                                :placeholder="isViewMode ? '' : 'Titular da Conta'"
                                :readonly="isViewMode"
                              />
                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col
                          cols="12"
                          md="4"
                        >
                          <validation-provider
                            #default="validationContext"
                            name="bank_name"
                          >
                            <b-form-group
                              label="Banco"
                              label-for="bank_name"
                            >
                              <b-form-input
                                id="bank_name"
                                v-model="supplier.bank_name"
                                :state="isViewMode ? null : getValidationState(validationContext)"
                                name="bank_name"
                                :placeholder="isViewMode ? '' : 'Banco'"
                                :readonly="isViewMode"
                              />
                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col
                          cols="12"
                          md="4"
                        >
                          <validation-provider
                            name="bank_agency"
                          >
                            <b-form-group
                              label="Agência"
                              label-for="bank_agency"
                            >
                              <cleave
                                id="bank_agency"
                                v-model="supplier.bank_agency"
                                name="bank_agency"
                                class="form-control"
                                :options="options.number"
                                :readonly="isViewMode"
                                :placeholder="isViewMode ? '' : 'Agência'"
                              />
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col
                          cols="12"
                          md="4"
                        >
                          <validation-provider
                            name="bank_account"
                          >
                            <b-form-group
                              label="Conta"
                              label-for="bank_account"
                            >
                              <cleave
                                id="bank_account"
                                v-model="supplier.bank_account"
                                name="bank_account"
                                class="form-control"
                                :options="options.number"
                                :readonly="isViewMode"
                                :placeholder="isViewMode ? '' : 'Conta'"
                              />
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>

              <b-row
                class="mt-2"
              >
                <b-col class="d-flex justify-content-end buttonsSaveCancel">
                  <b-button
                    v-if="!isViewMode"
                    id="btn-save"
                    type="submit"
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    :disable="invalid"
                  >
                    Salvar
                  </b-button>
                  <b-button
                    id="itemCancelButton"
                    variant="outline-primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    :to=" { name: 'supplier' } "
                  >
                    Voltar
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BButton,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardBody,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from 'bootstrap-vue'

import {
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate'

import {
  useToast,
} from 'vue-toastification/composition'

import {
  mdiBankCircleOutline,
  mdiPlusCircleOutline,
  mdiTrashCanOutline,
} from '@mdi/js'

import SvgIcon from '@jamescoyle/vue-icon'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import syslic from '@/syslic'
import router from '@/router'
import SyncLoad from '@/components/utils/syncLoad'
import AddressEditCard from '@/components/address/AddressEditCard.vue'
import ContactCard from '@/components/contact/ContactCard.vue'
import SupplierInfo from './components/SupplierInfo.vue'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
    BDropdown,
    BDropdownItem,
    BCard,
    BCardBody,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    ValidationObserver,
    ValidationProvider,
    SvgIcon,
    Cleave,
    SpinLoader,
    SupplierInfo,
    AddressEditCard,
    ContactCard,
  },
  directives: {
    Ripple,
  },
  data() {
    const requiredFieldsAddress = [
    ]

    const requiredFieldsParticipant = [
      'name',
    ]

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.supplier)

    let isViewMode = true
    let isCreateMode = false

    if (router.currentRoute.path.match(/.*\/edit\/.*/)
        || router.currentRoute.path.match(/.*\/create/)) {
      isViewMode = false
    }

    if (router.currentRoute.path.match(/.*\/create/)) {
      isCreateMode = true
    }

    const supplier = {
      id: '',
      address: '',
      address_type: '',
      address_type_set: '',
      neighborhood: '',
      neighborhood_type: '',
      neighborhood_type_set: '',
      complement: '',
      number: '',
      city: '',
      city_set: '',
      state: '',
      state_set: '',
      country: 1,
      country_set: {
        id: 1,
        name: 'Brasil',
        code: '1058',
      },
      zip_code: '',
      responsible: '',
      responsible_set: '',
      pix_key: '',
      account_owner: '',
      bank_name: '',
      bank_agency: '',
      bank_account: '',
      category: [],
      category_set: [],
      region_freight: '',
      region_freight_set: '',
    }

    const isLoaded = false

    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('supplier')
    syncLoad.addTrigger('supplier-info')

    syncLoad.onReady(() => {
      this.isLoaded = true
    })

    const options = {
      number: {
        numeral: true,
        numeralDecimalScale: 0,
        numeralPositiveOnly: true,
        delimiter: '',
      },
    }
    return {
      isViewMode,
      isCreateMode,
      refFormObserver,
      getValidationState,
      text_error: '',
      supplier,
      requiredFieldsAddress,
      requiredFieldsParticipant,
      supplierId: null,
      isLoaded,
      options,
      syncLoad,
    }
  },
  computed: {
    hasPermissionToUpdate() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.supplier.can_edit
    },
    hasPermissionToDelete() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.supplier.can_delete
    },
  },
  created() {
    const idToUpdate = router.currentRoute.params.id
    this.supplierId = router.currentRoute.params.id
    if (idToUpdate !== undefined) {
      syslic
        .supplier
        .fetchSupplier(router.currentRoute.params.id)
        .then(response => {
          this.supplier = response.data
          this.syncLoad.ready('supplier')
        })
        .catch(err => {
          if (err.status === 404) {
            router.push({ name: 'error-404' })
          }
          this.syncLoad.ready('supplier')
        })
    } else {
      this.syncLoad.ready('supplier')
    }
  },
  methods: {
    adjustDecimalPlaces(field, places) {
      const decimalReference = 10 * places
      const num = this.supplier[field]

      if (num !== '') {
        this.supplier[field] = ((num * decimalReference) / decimalReference).toFixed(places)
      }
    },
    createSupplier() {
      syslic
        .supplier
        .addSupplier(this.supplier)
        .then(response => {
          this.supplierId = response.data.id
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Fornecedor adicionado com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.disableEdition()
        })
        .catch(error => {
          let textError = 'Não foi possível adicionar o fornecedor, por favor entre em contato com o administrador do sistema.'

          if (error.data.includes('is not valid cnpj')) {
            textError = 'O CNPJ informado não é válido. Por favor informe um CNPJ válido para atualizar o fornecedor.'
          } else if (error.data.includes('duplicate key value violates unique constraint')) {
            textError = 'Já existe um fornecedor cadastrado com este CNPJ.'
          }

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao adicionar Fornecedor.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    updateSupplier() {
      syslic
        .supplier
        .updateSupplier(this.supplier.id, this.supplier)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Fornecedor atualizado com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.disableEdition()
        })
        .catch(error => {
          let textError = 'Não foi possível atualizar o fornecedor, por favor entre em contato com o administrador do sistema.'

          if (error.data.includes('is not valid cnpj')) {
            textError = 'O CNPJ informado não é válido. Por favor informe um CNPJ válido para atualizar o fornecedor.'
          } else if (error.data.includes('duplicate key value violates unique constraint')) {
            textError = 'Já existe um fornecedor cadastrado com este CNPJ.'
          }

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao atualizar o fornecedor.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    onSubmit() {
      if (this.isCreateMode) {
        this.createSupplier()
      } else {
        this.updateSupplier()
      }
    },
    deleteSupplier(id) {
      syslic
        .supplier
        .deleteSupplier(id)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Fornecedor removido com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          router.push({ name: 'supplier' })
        })
        .catch(error => {
          this.text_error = 'Não foi possível remover o fornecedor do servidor, por favor entre em contato com o administrador do sistema.'

          if ('data' in error) {
            if (error.data.includes('they are referenced through protected foreign keys')) {
              this.text_error = 'Este fornecedor é referenciado em algum produto, por este motivo não será possível exclui-lo.'
            }
          }

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao remover fornecedor.',
              text: this.text_error,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    showConfirmOrCancelDelete(item) {
      this.$bvModal
        .msgBoxConfirm('Você realmente quer remover esse fornecedor?', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteSupplier(item)
          }
        })
    },
    enableEdition() {
      router.push({ name: 'supplier-edit' })
    },
    disableEdition() {
      if (this.isCreateMode) {
        router.push({ name: 'supplier' })
      } else {
        router.push({
          name: 'supplier-detail',
          params: {
            id: this.supplierId,
          },
        })
          .catch(() => {})
      }

      this.isCreateMode = false
    },
  },
  setup() {
    const toast = useToast()
    const mask = {
      options: {
        cepDelimiter: {
          delimiters: ['-'],
          blocks: [5, 3],
          uppercase: true,
          required: true,
        },
        ieDelimiter: {
          delimiters: ['.', '.', '.'],
          blocks: [3, 3, 3, 3],
          uppercase: true,
        },
        cnpjDelimiter: {
          delimiters: ['.', '.', '/', '-'],
          blocks: [2, 3, 3, 4, 2],
          uppercase: true,
        },
      },
    }

    return {
      toast,
      mask,
      mdiBankCircleOutline,
      mdiPlusCircleOutline,
      mdiTrashCanOutline,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.disabledEdition .input-group-text{
  background-color: #efefef;
}

input::placeholder {
  color: #b9b9c3 !important;
}

.buttonsSaveCancel{
  .btn{
    min-width: 107px;
  }
}
</style>
